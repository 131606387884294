import {
  AgxBodyText,
  AgxColumn,
  AgxLabel,
  AgxRadio,
  AgxRow,
  AgxSelect,
  AgxTextInput,
  Images,
} from '@urbanx/agx-ui-components';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import './CommissionSplit.scss';
import React from 'react';

const CommissionSplit = ({
  defaultValue = {},
  label,
  validate,
  onValueChanged,
  id,
}) => {
  const { splittingWithSecondaryAgent, leadAgentSplit, secondAgentSplit } =
    defaultValue;

  const { agents } = useSelector(state => state.listingAgent);

  const agentOptions = [
    {
      value: '',
      label: 'Please select an agent',
    },
  ].concat(
    agents.map(agent => {
      return {
        value: agent.id,
        label: `${agent.name?.firstName} ${agent.name?.lastName}`,
      };
    })
  );

  const [localError, setLocalError] = useState('');
  const [totalPercentage, setTotalPercentage] = useState('');
  const [commissionSplit, setCommissionSplit] = useState({
    splittingWithSecondaryAgent: splittingWithSecondaryAgent ?? false,
    leadAgentSplit: leadAgentSplit ?? {
      agentId: '',
      percentage: '',
    },
    secondAgentSplit: secondAgentSplit ?? {
      agentId: '',
      percentage: '',
    },
  });

  const updateLocalError = commissionSplit => {
    const { leadAgentSplit, secondAgentSplit } = commissionSplit;
    const commissionPercentageTotal =
      (Number(leadAgentSplit.percentage) || 0) +
      (Number(secondAgentSplit.percentage) || 0);

    setTotalPercentage(commissionPercentageTotal);

    if (
      (!leadAgentSplit.agentId && !leadAgentSplit.percentage) ||
      (!secondAgentSplit.agentId && !secondAgentSplit.percentage)
    ) {
      setLocalError('Select agent and commission');
    } else if (!leadAgentSplit.agentId || !secondAgentSplit.agentId) {
      setLocalError('Select agent');
    } else if (!leadAgentSplit.percentage || !secondAgentSplit.percentage) {
      setLocalError('Enter commission');
    } else if (commissionPercentageTotal !== 100) {
      setLocalError('Total commission percentage should be exactly 100%');
    } else {
      setLocalError('');
    }
  };

  useEffect(() => {
    onValueChanged({
      id,
      value: {
        ...commissionSplit,
      },
    });
  }, [commissionSplit]);

  return (
    <div className="agxRadio">
      <AgxLabel medium>{label}</AgxLabel>
      <div className="agxRadio-group">
        <AgxRadio
          id={'noCommisionSplit'}
          label={`No`}
          onCheckChanged={() =>
            setCommissionSplit({
              ...commissionSplit,
              splittingWithSecondaryAgent: false,
            })
          }
          checked={commissionSplit.splittingWithSecondaryAgent === false}
        />
        <AgxRadio
          id={`yesCommisionSplit`}
          label={`Yes`}
          onCheckChanged={() => {
            setCommissionSplit({
              ...commissionSplit,
              splittingWithSecondaryAgent: true,
            });
          }}
          checked={commissionSplit.splittingWithSecondaryAgent === true}
          subContent={
            commissionSplit.splittingWithSecondaryAgent === true && [
              <AgxColumn largeGap fill>
                <AgxRow centered mediumGap fill>
                  <AgxSelect
                    id={`leadAgent`}
                    label="Lead Agent"
                    options={agentOptions}
                    error={
                      validate && !commissionSplit.leadAgentSplit.agentId
                        ? localError
                        : undefined
                    }
                    hideErrorDescription
                    onValueChanged={({ value }) => {
                      const obj = {
                        ...commissionSplit,
                        leadAgentSplit: {
                          ...commissionSplit.leadAgentSplit,
                          agentId: value,
                        },
                      };
                      setCommissionSplit(obj);
                      updateLocalError(obj);
                    }}
                    dataTestId={`agx-commissionSplit-leadAgent`}
                    required
                    defaultValue={
                      agentOptions.find(
                        agent =>
                          agent.value ===
                          commissionSplit?.leadAgentSplit?.agentId
                      ) ?? agentOptions[0]
                    }
                  />
                  <AgxTextInput
                    id="leadAgentPercent"
                    noHeader
                    noOptionalLabel
                    percent
                    onInputValueChange={({ value }) => {
                      const obj = {
                        ...commissionSplit,
                        leadAgentSplit: {
                          ...commissionSplit.leadAgentSplit,
                          percentage: value,
                        },
                      };
                      setCommissionSplit(obj);
                      updateLocalError(obj);
                    }}
                    parentControlValue
                    defaultValue={commissionSplit.leadAgentSplit.percentage}
                    extraClasses="commissionPercentage"
                    error={
                      validate &&
                      (!commissionSplit.leadAgentSplit.percentage ||
                        totalPercentage !== 100)
                        ? localError
                        : ''
                    }
                    hideErrorDescription
                  />
                </AgxRow>
                <AgxRow centered mediumGap>
                  <AgxSelect
                    id={`secondAgent`}
                    label="Second Agent"
                    options={agentOptions}
                    error={
                      validate && !commissionSplit.secondAgentSplit.agentId
                        ? localError
                        : undefined
                    }
                    hideErrorDescription
                    onValueChanged={({ value }) => {
                      const obj = {
                        ...commissionSplit,
                        secondAgentSplit: {
                          ...commissionSplit.secondAgentSplit,
                          agentId: value,
                        },
                      };
                      setCommissionSplit(obj);
                      updateLocalError(obj);
                    }}
                    dataTestId={`agx-commissionSplit-secondAgent`}
                    required
                    defaultValue={
                      agentOptions.find(
                        agent =>
                          agent.value ===
                          commissionSplit?.secondAgentSplit?.agentId
                      ) ?? agentOptions[0]
                    }
                  />
                  <AgxTextInput
                    id="secondAgentPercent"
                    noHeader
                    noOptionalLabel
                    percent
                    parentControlValue
                    defaultValue={commissionSplit.secondAgentSplit.percentage}
                    onInputValueChange={({ value }) => {
                      const obj = {
                        ...commissionSplit,
                        secondAgentSplit: {
                          ...commissionSplit.secondAgentSplit,
                          percentage: value,
                        },
                      };
                      setCommissionSplit(obj);
                      updateLocalError(obj);
                    }}
                    extraClasses="commissionPercentage"
                    error={
                      validate &&
                      (!commissionSplit.secondAgentSplit.percentage ||
                        totalPercentage !== 100)
                        ? localError
                        : ''
                    }
                    hideErrorDescription
                  />
                </AgxRow>
                {validate && localError && (
                  <AgxRow mediumGap>
                    <Images.AlertCircle />
                    <AgxBodyText small extraClasses={'errorMessage'}>
                      {localError}
                    </AgxBodyText>
                  </AgxRow>
                )}
              </AgxColumn>,
            ]
          }
        />
      </div>
    </div>
  );
};

export default CommissionSplit;
